















































































































import { Component } from 'vue-property-decorator';

import EvaluationDataTable from '@/components/evaluation/EvaluationDataTable.vue';
import MixinMain from '@/mixing/MixinMain.vue';
import AppContainerBase from '@/components/util/AppContainerBase.vue';
import { IItemSelect } from '@/model/util/IItemSelect';
import { internet } from '@/utils/Internet';
import Util from '@/utils/Util';
import { ITranscripData } from '@/model/transcription/ITranscripData';
import { Icon } from '@/model/util/Icon';
@Component({
	name: 'MainEvaluation.vue',
	components: {
		EvaluationDataTable,
		AppContainerBase,
	},
})
export default class MainEvaluation extends MixinMain {
	public isLoading = false;
	public selectCustomer: Array<IItemSelect> = [];
	public selectCamping: Array<IItemSelect> = [];
	public selectAgent: Array<IItemSelect> = [];
	public isOpenDate = false;
	public selected: Array<string> = ['', '', '', ''];
	public disables: Array<boolean> = [true, true];

	mounted() {
		this.isLoading = true;
		internet
			.newRequest()
			.get('separacion/cliente')
			.then((response) => {
				this.selectCustomer.push({
					text: 'Seleccione...',
					value: '',
				});
				for (let item of response.data
					.response as Array<
					Record<string, string>
				>) {
					this.selectCustomer.push({
						text: item._id,
						value: item._id,
					});
				}
			})
			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
			});
	}

	public loadCampaing() {
		if (this.selected[0] !== '') {
			this.isLoading = true;
			internet
				.newRequest()
				.get(
					`separacion/campana/${this.selected[0]}`
				)
				.then((response) => {
					this.selectCamping.push({
						text: 'Seleccione...',
						value: '',
					});
					for (let item of response.data.response
						.Campania as Array<
						Record<string, string>
					>) {
						this.selectCamping.push({
							text: item,
							value: item,
						});
					}
					this.disables[0] = false;
				})
				.catch(console.log)
				.finally(() => {
					this.isLoading = false;
				});
		}
	}
	public loadAgent() {
		if (this.selected[1] !== '') {
			this.isLoading = true;
			internet
				.newRequest()
				.get(`xentric/agente/${this.selected[1]}`)
				.then((response) => {
					this.selectAgent.push({
						text: 'Seleccione...',
						value: '',
					});
					for (let item of response.data
						.response as Array<
						Record<string, string>
					>) {
						this.selectAgent.push({
							text: item._id,
							value: item._id,
						});
					}
					this.disables[1] = false;
				})
				.catch(console.log)
				.finally(() => {
					this.isLoading = false;
				});
		}
	}
	public sendTo() {
		this.isLoading = true;
		let error = false;
		for (let value of this.selected) {
			if (value === '') {
				error = true;
				break;
			}
		}
		if (error) {
			Util.showMessage(
				this.$t("DataTable.warning") as string,
				Icon.INFO,
				this.$t("DataTable.cuidado") as string
			);
			this.isLoading = false;
			return;
		}
		internet
			.newRequest()
			.get(
				`separacion/FechaTranscripcion/${this.selected[2]}/${this.selected[3]}`
			)
			.then((response) => {
				if (response.data.length > 0) {
					Util.showMessage(
						'Información cargada',
						Icon.SUCCESS
					);
					const data: Array<ITranscripData> = [];
					for (let item of response.data) {
						data.push(item.response);
					}
					localStorage.setItem(
						'filter',
						JSON.stringify(data)
					);
					this.$router.push({
						name: 'SelectTranscription',
					});
				} else {
					Util.showMessage(
						'No hay audios en los filtros especificados',
						Icon.INFO
					);
				}
			})
			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
			});
	}
}
